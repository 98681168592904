import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderTopNews from "../common/header/HeaderTopNews";
import BlogClassicData from "../data/blog/BlogList.json";
var BlogListData = BlogClassicData.slice(0, 3);

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Fabriq" />
      <main className="d-flex flex-column vh-100">
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        <div className="d-flex bg-transparent flex-grow-1 align-items-center justify-content-center">
          <div className="bg-transparent">
            <div style={{ marginBottom: 16 }}>
              <HeaderTopNews />
            </div>
            <div className="inner text-center">
              <h1 className="title theme-gradient display-two">
                Everyday technology <br />{" "}
                <Typed
                  strings={["IoT Apps", "Mobile Apps", "Web Apps"]}
                  typeSpeed={80}
                  backSpeed={5}
                  backDelay={1000}
                  loop
                />
              </h1>
              <p className="description">
                Crafting technology to make our daily lives better and
                entuitive.
              </p>
              {/* <div className="button-group">
                    <a
                      className="btn-default btn-medium round btn-icon"
                      target="_blank"
                      href="#apps"
                    >
                      Learn more{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div> */}
            </div>
          </div>
        </div>

        <div className="copyright-right text-center">
          <p className="copyright-text">© Fabriq {new Date().getFullYear()}</p>
        </div>
      </main>
    </>
  );
};

export default BusinessConsulting2;
