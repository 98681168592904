import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";

const HeaderTopNews = () => {
  const [deactive, setClass] = useState("");
  return (
    <div className={`header-top-news bg_image ${deactive}`}>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <div className="content">
                  <span className="rn-badge">Announcement</span>
                  <span className="news-text">
                    Introducing <strong>Pass</strong>: The world's most
                    versatile cards wallet.
                  </span>
                </div>
                <div className="right-button">
                  <a
                    className="btn-small"
                    target="_blank"
                    href="https://pass-card.app/"
                  >
                    <span>
                      Learn more <FiArrowRight />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="icon-close">
        <button className="close-button" onClick={() => setClass("deactive")}>
          <FiX />
        </button>
      </div> */}
    </div>
  );
};

export default HeaderTopNews;
