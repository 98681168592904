import { useState, useRef } from "react";
import Logo from "../../elements/logo/Logo";
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";

const HeaderTwo = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;
  const { clientHeight } = ref;

  const checkChange = (value) => {
    setCheck(value);
  };

  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default header-left-align ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-9 col-md-6 col-4 position-static">
              <div className="header-left d-flex">
                <Logo
                  image={`${process.env.PUBLIC_URL}/logo512.png`}
                  image2={`${process.env.PUBLIC_URL}/logo512.png`}
                />
                <nav className="mainmenu-nav d-none d-lg-block">
                  {/* <Nav /> */}
                </nav>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-8">
              <div className="header-right">
                <div className="header-btn">
                  <a
                    className={`btn-default ${btnStyle}`}
                    target="_blank"
                    href="mailto:contact@fabriq.rw"
                  >
                    Contact us
                  </a>
                </div>
                <Darkmode />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default HeaderTwo;
